* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 9px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #eaeceb;
}

::-webkit-scrollbar-thumb {
  background: #9368b6;
}

::-webkit-scrollbar-thumb:hover {
  background: #623d80;
}
