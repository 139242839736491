.has-blur {
	animation: 0.2s all ease;
}

@media (min-width: 985px) {
	.blur-image {
		filter: blur(10px);
	}
}

@keyframes bluring {
	from {
		filter: blur(10px);
	}
	to {
		filter: blur(0px);
	}
}